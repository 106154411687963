#infographic-energy-supply {
  #energysupply_sun,
  #energysupply_birds,
  #energysupply_day_clouds_left,
  #energysupply_day_clouds_right {
    visibility: hidden;
  }

  &.visible {
    #energysupply_sun,
    #energysupply_birds,
    #energysupply_day_clouds_left,
    #energysupply_day_clouds_right {
      visibility: visible;
    }
  }

  #energysupply_night_clouds_left_5 {
    animation-duration: 6s;
  }

  #energysupply_night_clouds_right_4 {
    animation-duration: 5.5s;
  }

  #energysupply_night_clouds_left_4 {
    animation-duration: 5s;
  }

  #energysupply_night_clouds_right_3 {
    animation-duration: 4.5s;
  }

  #energysupply_night_clouds_left_3 {
    animation-duration: 4s;
  }

  #energysupply_night_clouds_right_2 {
    animation-duration: 3.5s;
  }

  #energysupply_night_clouds_left_2 {
    animation-duration: 3s;
  }

  #energysupply_night_clouds_right_1 {
    animation-duration: 2.5s;
  }

  #energysupply_night_clouds_left_1 {
    animation-duration: 2s;
  }

  #energysupply_birds {
    animation-delay: 1s;
    animation-duration: 3s;
  }

  #energysupply_sun,
  #energysupply_day_clouds_left,
  #energysupply_day_clouds_right {
    animation-duration: 5s;
  }
}
