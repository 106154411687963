@keyframes seatedChairScoot {
  from {
    transform: translate3d(-10%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

#infographic-central-office {
  #centraloffice_person_left,
  #centraloffice_person_right,
  #centraloffice_computer_metric_1,
  #centraloffice_computer_metric_2,
  #centraloffice_computer_metric_3,
  #centraloffice_computer_metric_line {
    visibility: hidden;
  }

  &.visible {
    #centraloffice_person_left,
    #centraloffice_person_right,
    #centraloffice_computer_metric_1,
    #centraloffice_computer_metric_2,
    #centraloffice_computer_metric_3,
    #centraloffice_computer_metric_line {
      visibility: visible;
    }
  }

  #centraloffice_person_left {
    animation-duration: 4s;
  }

  #centraloffice_person_right {
    animation-delay: 1s;
    animation-duration: 3s;
  }

  #centraloffice_person_seated {
    animation-duration: 3s;
    &.slideInLeft {
      animation-name: seatedChairScoot;
    }
  }

  #centraloffice_computer_metric_line {
    animation-delay: 3.5s;
  }
  #centraloffice_computer_metric_1 {
    animation-delay: 4s;
  }
  #centraloffice_computer_metric_2 {
    animation-delay: 4.5s;
  }
  #centraloffice_computer_metric_3 {
    animation-delay: 5s;
  }
}
