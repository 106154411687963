.uk-open > .uk-modal-dialog {
  transform: translateY(0) translateX(0);
}

.sidebar {
  @media (max-width: $breakpoint-small-max) {
    margin-left: -15px;
    width: 100vw;
    height: auto;
  }
}

.sidebar-nav {
  text-align: center;

  .sidebar-nav-icon {
    position: relative;
    display: block;
    width: 85%;
    padding-bottom: 85%;
    height: 0;
    margin: 0 auto;
    border-radius: 50%;
    background-color: $color-teal;
    background-position: center center;
    background-size: cover;

    img {
      position: absolute;
      width: 40%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      border-radius: 50%;
      background-color: rgba($color-teal, 0.7);
      transition: all 0.1s linear;
    }
  }

  .sidebar-nav-title {
    display: block;
  }

  .sidebar-nav-link {
    font-size: 75%;
    display: block;
  }

  &:hover {
    .sidebar-nav-icon::before {
      background-color: rgba($color-teal, 0.9);
    }
  }
}

.sidebar-panel {
  h4 {
    color: $color-cardinal-red;
    @media (max-width: $breakpoint-small-max) {
      margin-top: 0 !important;
    }

    svg {
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: $margin-small-margin;
      margin-top: 0;
      @media (min-width: $breakpoint-medium) {
        display: inline-block;
        margin-left: 0;
        margin-right: $margin-small-margin;
        margin-bottom: 0;
        margin-top: 0;
      }
    }
  }

  .uk-modal-dialog.uk-padding {
    padding-bottom: 0;
    @media (min-width: $breakpoint-medium) {
      padding-bottom: inherit;
    }

    .uk-modal-close-full {
      top: 70px;
    }
  }

  &.sidebar-panel-links {
    .background {
      @media (max-width: $breakpoint-xsmall-max) {
        .uk-grid-margin {
          &.uk-first-column {
            width: 45%;
          }
          &:not(.uk-first-column) {
            width: 55%;
          }
        }
      }

      .corner-left-bottom {
        @media (min-width: $breakpoint-small) {
          margin-left: $margin-margin;
          margin-right: $margin-margin;
          margin-top: $margin-margin;
        }
      }
      .corner-right-top {
        @media (min-width: $breakpoint-small) {
          margin-left: $margin-medium-margin;
          margin-top: $margin-medium-margin;
        }
      }
    }

    figcaption {
      background: radial-gradient(
        closest-side,
        rgba(#fff, 0.9),
        rgba(#fff, 0.9),
        transparent
      );

      padding-bottom: 0;
      @media (min-width: $breakpoint-small) {
        position: absolute;
        right: 0;
        bottom: 0;
      }

      ul.uk-list {
        text-shadow: 0 0 9px $color-white;
        margin-bottom: 0;

        li {
          padding-left: 2em;

          a {
            font-weight: 600;
            position: relative;
          }
        }

        i {
          position: absolute;
          left: -1.5em;
          top: 2pt;
        }
      }
    }
  }

  @media (max-width: $breakpoint-small-max) {
    &.uk-modal {
      position: static;
      display: block;
      opacity: 1;
      overflow: visible;
      .uk-modal-dialog {
        transition: none;
        transform: translateY(0) translateX(0);
        padding-left: 0;
        padding-right: 0;
        background-color: inherit;
      }
    }
  }
  @media (min-width: $breakpoint-medium) {
    right: calc(50% - 10px);

    .uk-modal-dialog {
      min-height: 100%;
    }
  }
  @media (min-width: $breakpoint-large) {
    right: 40%;
  }
}

#academics {
  .sidebar-panel.sidebar-panel-links {
    figcaption {
      @media (min-width: $breakpoint-medium) and (max-width: $breakpoint-medium-max) {
        top: 0;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}

.chart-container {
  position: relative;
  width: 100%;
  height: $height-large-height;
  max-height: 100vh;
}
