#infographic-food-and-living-inside {
  #foodandliving_inside_person {
    visibility: hidden;
  }

  &.visible {
    #foodandliving_inside_person {
      visibility: visible;
    }
  }

  #foodandliving_inside_person {
    animation-duration: 4s;
  }
}

#infographic-food-and-living-outside {
  #foodandliving_outside_person {
    visibility: hidden;
  }

  &.visible {
    #foodandliving_outside_person {
      visibility: visible;
    }
  }

  #foodandliving_outside_person {
    animation-duration: 4s;
  }
}
