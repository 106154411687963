@keyframes gafferLadderClimb {
  from {
    transform: translate3d(2%, 10%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

#infographic-buildings {
  #buildings_plumber,
  #buildings_electrician {
    visibility: hidden;
  }

  &.visible {
    #buildings_plumber,
    #buildings_electrician {
      visibility: visible;
    }
  }

  #buildings_plumber {
    animation-duration: 5s;
  }

  #buildings_electrician {
    animation-delay: 1.5s;
    animation-duration: 4s;
  }

  #buildings_gaffer {
    animation-duration: 2s;
    &.slideInUp {
      animation-name: gafferLadderClimb;
    }
  }
}
