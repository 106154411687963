#infographic-transportation {
  #transportation_bus,
  #transportation_van,
  #transportation_cyclist_left,
  #transportation_cyclist_right {
    visibility: hidden;
  }

  &.visible {
    #transportation_bus,
    #transportation_van,
    #transportation_cyclist_left,
    #transportation_cyclist_right {
      visibility: visible;
    }
  }

  #transportation_bus {
    animation-delay: 0;
    animation-duration: 2.5s;
  }

  #transportation_van {
    animation-delay: 0.5s;
    animation-duration: 3s;
  }

  #transportation_cyclist_left {
    animation-delay: 1.8s;
    animation-duration: 3s;
  }

  #transportation_cyclist_right {
    animation-delay: 1.8s;
    animation-duration: 2.5s;
  }
}
