#infographic-academics {
  #academics_whiteboard,
  #academics_graduate,
  #academics_professor,
  #academics_chemist {
    visibility: hidden;
  }

  &.visible {
    #academics_whiteboard,
    #academics_graduate,
    #academics_professor,
    #academics_chemist {
      visibility: visible;
    }
  }

  #academics_whiteboard {
    animation-duration: 1s;
  }

  #academics_professor {
    animation-duration: 3s;
  }

  #academics_chemist {
    animation-duration: 4s;
  }

  #academics_graduate {
    animation-delay: 1s;
    animation-duration: 4s;
  }
}
