a[href^="http"] > i.fa-external-link-square-alt {
  color: $color-teal;
}

main > section {
  overflow: hidden;
}

.section-intro-photo {
  width: calc(100% + #{3 * $margin-small-margin});
  margin-left: -1.5 * $margin-small-margin;
  margin-bottom: -1 * $margin-margin;

  @media (min-width: $breakpoint-small) {
    width: calc(100% + #{4 * $margin-margin});
    margin-left: -2 * $margin-margin;
    margin-bottom: -1 * $margin-margin;
  }

  @media (min-width: $breakpoint-medium) {
    width: calc(100% + #{2 * $margin-large-margin});
    margin-left: -1 * $margin-large-margin - $margin-small-margin;
    margin-bottom: inherit;
  }

  .uk-cover-container {
    min-height: $height-medium-height;
    @media (min-width: $breakpoint-medium) {
      height: $height-large-height;
    }
  }

  figcaption {
    @media (max-width: $breakpoint-small-max) {
      position: static !important;
      transform: translateY(-1 * $margin-margin) !important;
      margin-left: $margin-small-margin;
      margin-top: 0;
      margin-right: $margin-small-margin;
      margin-bottom: 0;
      max-width: calc(100% - #{$margin-margin});
    }
  }
}

#summary {
  p.uk-dropcap:first-letter {
    color: $color-cardinal-red;
  }

  #summary-landing {
    position: relative;

    .background {
      width: 100%;
      margin-bottom: $margin-large-margin;

      .background-left-top {
        padding-left: $margin-small-margin;
        padding-right: $margin-medium-margin;
      }
      .background-right-top {
        z-index: -1;
      }
      .background-left-bottom {
        padding-right: $margin-small-margin;
        margin-top: $margin-medium-margin;
      }
      .background-right-bottom {
        padding-left: $margin-small-margin;
        padding-right: $margin-small-margin;
      }
    }

    @media (min-width: $breakpoint-small) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      .background {
        padding: $margin-large-margin;
        padding-top: 0;

        .background-left-top {
          padding-left: $margin-large-margin;
          padding-right: 0;
          margin-top: $margin-large-margin;
          margin-left: 0;
          margin-right: 0;
          order: 0;
          z-index: -1;
        }
        .background-right-top {
          order: 1;
          padding-left: $margin-large-margin;
          margin-top: $margin-large-margin;
          z-index: -1;
        }
        .background-left-bottom {
          order: 2;
          margin-left: inherit;
          padding-left: $margin-large-margin;
          margin-right: inherit;
          margin-top: $margin-large-margin;
          z-index: -1;
        }
        .background-right-bottom {
          order: 3;
          margin-top: $margin-large-margin;
          padding-left: $margin-large-margin;
          z-index: -1;
        }
      }
    }

    @media (min-width: $breakpoint-medium) {
      min-height: calc(100vh - 60px);
    }

    .hero {
      display: flex;
      justify-content: center;
      transform: translateY(-1 * $margin-margin);
      margin-bottom: -1 * $margin-small-margin;
      margin-left: $margin-small-margin;
      margin-right: $margin-small-margin;

      @media (min-width: $breakpoint-small) {
        justify-content: flex-start;
        transform: none;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 0;
        z-index: 0;
      }

      header {
        display: inline-block;
        padding: 0;

        @media (min-width: $breakpoint-small) {
          position: absolute;
          top: calc(50% - 10em);
          margin-left: $margin-large-margin;
        }
      }

      h1 {
        display: inline-block;
        line-height: 1.2;
        position: relative;
        margin-top: $margin-small-margin;
        margin-bottom: $margin-small-margin;
        margin-left: $margin-margin;
        margin-right: $margin-margin;
      }

      small {
        font-size: 0.5625em;
        line-height: 1.75;
        display: block;
        font-weight: normal;
        text-transform: uppercase;
      }

      * {
        color: $color-cardinal-red;
      }
    }
  }

  #summary-intro {
    .section-intro-photo {
      .uk-cover-container {
        min-height: $height-medium-height;
        @media (min-width: $breakpoint-medium) {
          height: 80vh;
        }
      }
    }
  }

  #summary-article {
    aside figure {
      padding-top: inherit;
      @media (min-width: $breakpoint-medium) {
        margin-top: 3 * $margin-medium-margin;
        padding-top: $margin-large-margin;
      }
    }

    .aashe-stars {
      background: url(../media/logos/stars_platinum.png) top 2em right 3em /
        120px no-repeat $color-white;
      margin-bottom: -1 * $margin-large-margin;

      svg {
        color: $color-cardinal-red;
      }
    }
  }

  #summary-sdgs {
    article {
      margin-top: $margin-xlarge-margin;
      @media (min-width: $breakpoint-medium) {
        margin-top: inherit;
      }
    }
  }
}

.pull-left {
  width: 100%;
  text-align: center;
  @media (min-width: $breakpoint-medium) {
    width: 60%;
    float: left;
    margin-left: -10%;
    margin-right: 1em;
  }
}
