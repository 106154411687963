// 1. Your custom variables and variable overwrites.
// Refer to ../node_modules/uikit/src/scss/variables.scss for all UIKit variables to override
$color-cardinal-red: #820000;
$color-gold: #eaab00;
$color-teal: #007c92;
$color-dark: #4d4f53;
$color-darker: #3c3c3c;
$color-darkest: #2e2d29;
$color-medium: #cecece;
$color-light: #f1f1f1;
$color-white: #fcfcfc;

$global-color: $color-darkest;
$base-body-background: $color-white;
$global-primary-background: $color-gold;
$global-secondary-background: $color-teal;
$global-muted-background: $color-light;

$global-link-color: $color-cardinal-red;
$global-link-hover-color: $color-cardinal-red;
$global-inverse-color: $color-white;
$global-border: $color-white;
$base-selection-background: $color-gold;

$font-stack-sans: "Source Sans Pro", Helvetica, arial, sans-serif;
$font-stack-serif: "Source Serif Pro", serif;

$global-font-family: $font-stack-sans;
$base-heading-font-family: $font-stack-sans;
$base-heading-font-weight: bold;

$global-line-height: 1.375;
$global-small-gutter: 16px;
$global-gutter: 40px;
$global-medium-gutter: 50px;

$navbar-nav-item-height: 60px;
$offcanvas-bar-background: $color-cardinal-red;

// 2. Import default variables and available mixins
@import "~uikit/src/scss/variables.scss";
@import "~uikit/src/scss/mixins.scss";

// 3. Custom mixins / overrides
@import "./mixins";

// 4. Import UIkit
@import "~uikit/src/scss/uikit.scss";

// 5. Custom styles
@import "./corners";
@import "./interstitials";
@import "./navigation";
@import "./sections";
@import "./sidebars";
@import "./infographics";
