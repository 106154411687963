@import "./academics";
@import "./energy-supply";
@import "./central-office";
@import "./buildings";
@import "./food-and-living";
@import "./transportation";

.animated-infographic svg {
  width: 100%;
}
