#totop {
  font-size: 1.5rem;
  text-align: center;
  display: block;
  position: fixed;
  bottom: $global-small-gutter;
  right: $global-small-gutter;
  width: 50px;
  line-height: 50px;
  z-index: 1000;
}

#summary-scroll-down {
  width: 100%;
  font-size: 1.5rem;
  text-align: center;
  display: block;
  line-height: 50px;
  @media (min-width: $breakpoint-medium) {
    width: 50px;
    position: absolute;
    z-index: 100;
    top: calc(100vh - #{2 * $margin-xlarge-margin});
    left: calc(50% - #{2 * $margin-margin});
    transform: translateX(-25px);
  }
}

.subtle {
  color: rgba($color-dark, 0.3);
  transition: all 0.1s linear;

  &:hover,
  &:active {
    color: rgba($color-dark, 0.7);
  }
}

.nav-offset {
  height: 60px;
}

header.uk-navbar {
  position: fixed;
  z-index: 1000000;
  padding: 0 10px;

  top: 0;
  left: $global-small-gutter;
  right: $global-small-gutter;

  left: calc(env(safe-area-inset-left) + #{$global-small-gutter});
  right: calc(env(safe-area-inset-right) + #{$global-small-gutter});

  @media (min-width: $breakpoint-large) {
    left: $global-medium-gutter;
    right: $global-medium-gutter;

    left: calc(env(safe-area-inset-left) + #{$global-medium-gutter});
    right: calc(env(safe-area-inset-right) + #{$global-medium-gutter});
  }

  .uk-navbar-nav {
    @media (max-width: $breakpoint-medium-max) {
      li a {
        padding: 0 5px;
      }
    }
  }
}
