@mixin hook-heading-small() {
  font-family: $font-stack-serif;
}

@mixin hook-base-blockquote() {
  font-family: $font-stack-serif;
  font-style: normal;
  font-weight: bold;
  padding-top: 6pt;

  @media (min-width: $breakpoint-medium) {
    padding-top: inherit;
  }

  &::after {
    content: "“";
    position: absolute;
    font-size: 128px;
    font-weight: bold;
    color: $color-gold;
    z-index: 0;
    top: -22pt;
    left: 3pt;
    @media (min-width: $breakpoint-medium) {
      font-size: 11.25rem;
      top: -0.375em;
      left: -0.1em;
    }
  }

  p {
    z-index: 1;
    position: relative;
  }
}

@mixin hook-base-blockquote-footer() {
  color: $color-cardinal-red;
  font-family: $font-stack-sans;
  font-weight: normal;
}

@mixin hook-base-link() {
  font-weight: bold;
  text-decoration: none;
}
@mixin hook-base-link-hover() {
  text-decoration: underline;
}

@mixin hook-dropcap() {
  color: $color-teal;
  font-family: $font-stack-serif;
  font-size: 3.75em;
  line-height: 0.75;
  margin-right: 0.1em;
  margin-bottom: -0.2em;
}

@mixin hook-button() {
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1pt;
}

@mixin hook-button-link() {
  color: $color-cardinal-red;
}
@mixin hook-button-text() {
  color: $color-dark;
}

@mixin nav-item-underline() {
  background-image: linear-gradient(
    to bottom,
    $global-color,
    $global-color 50%,
    transparent 50%
  );
  background-position: left 38px;
  background-repeat: repeat-x;
  background-size: 4px 4px;
  background-clip: content-box;
}

@mixin hook-navbar-container() {
  background-color: rgba(255, 255, 255, 0.9);
}

@mixin hook-navbar-nav-item() {
  font-size: 13px;
  line-height: 2;
  font-weight: bold;
  text-align: center;
  padding: 0 10px;
  margin: 0 0;
  color: $global-color;
}

@mixin hook-navbar-nav-item-hover() {
  @include nav-item-underline();
}
@mixin hook-navbar-nav-item-active() {
  @include nav-item-underline();
}

@mixin hook-nav-primary-item() {
  font-weight: bold;
}

@mixin hook-offcanvas-bar() {
  padding-left: calc(
    env(safe-area-inset-left) + #{$offcanvas-bar-padding-horizontal}
  );
}

@mixin hook-modal() {
  opacity: 1;
  transition: none;
}

@mixin hook-modal-dialog() {
  opacity: 1;
}

@mixin hook-modal-full() {
  transition: 0.3s ease;
  transition-property: transform;
  transform: translateY(0) translateX(-100vw);
}
