.corner {
  position: relative;
  display: inline-block;
  z-index: auto;
  padding: 1pt;

  &::before {
    content: "";
    position: absolute;
    width: 50%;
    min-width: 3em;
    height: 40%;
    min-height: 3em;
    background-color: $color-teal;
    z-index: -1;
    transition: all 0.1s linear;
  }

  &.corner-teal::before {
    background-color: $color-teal;
  }
  &.corner-gold::before {
    background-color: $color-gold;
  }
  &.corner-left-top::before {
    left: -2em;
    top: -1.8em;
    transform-origin: left top;
  }
  &.corner-left-bottom::before {
    left: -2em;
    bottom: -1.8em;
    transform-origin: left bottom;
  }
  &.corner-right-top::before {
    right: -2em;
    top: -1.8em;
    transform-origin: right top;
  }
  &.corner-right-bottom::before {
    right: -2em;
    bottom: -1.8em;
    transform-origin: right bottom;
  }

  &.corner-focus {
    z-index: 0;

    &::before {
      min-width: 6em;
      min-height: 6em;
      max-width: 10em;
      max-height: 10em;
      transform: scale(0);
    }
  }

  &.corner-visible {
    &::before {
      transform: scale(1);
    }
  }
}

.sidebar-panel {
  .corner.corner-left-bottom.corner-gold::before {
    width: 80%;
    left: -1.5em;
  }
}

article {
  figure .corner.corner-right-top::before {
    top: -1.5em;
    right: -3em;
  }

  .corner.corner-focus.corner-gold.corner-left-top::before {
    top: -1.25em;
    min-width: inherit;
    width: 4em;
    min-height: inherit;
    height: 4.75em;
  }
}

#summary-landing {
  .corner.corner-left-bottom::before {
    left: -15%;
    bottom: -7%;
    width: 80%;
    height: 40%;
  }
}

#summary-intro {
  .corner.corner-right-bottom::before {
    right: -1.5em;
    bottom: -3em;
    width: 55%;
    height: 40%;
  }
}

#summary {
  .corner.corner-focus.corner-left-top::before {
    left: -3em;
    top: -2.5em;
    width: 7em;
    height: 7em;
  }
}
